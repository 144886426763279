import * as React from 'react'

import { Container } from '../../shared/Grid'
import { Box, Flex } from '../../shared/Helpers'
import { Image, ImageRounded } from '../../shared/Image'
import Link from '../../shared/Link'
import { Description, MainTitle } from '../../shared/Typography'
import { ImageTextured } from './parts'
import { IHero } from './types'

const dotsCareers = require('../../../images/dotsCareers.svg')

interface IProps {
  data: IHero
}

export default function renderHeroCareers({ data }: IProps) {
  const { description } = data

  const links = description.match(/(?:__|[*#])|\[(.*?)\]\(.*?\)/gm)
  const formatted: React.ReactNode[] = [description]

  if (links) {
    links.forEach((el: string) => {
      const text = el.match(/\[(.+)]\((.+)\)/)![1]
      const url = el.match(/\[(.+)]\((.+)\)/)![2]

      const [start, ...rest] = (formatted[
        formatted.length - 1
      ] as string).split(el)

      formatted[formatted.length - 1] = start
      formatted.push(
        <Link key={text} color="blue" to={url}>
          {text}
        </Link>
      )
      rest.forEach(r => formatted.push(r))
    })
  }

  const padd = [0, '0 6em', '0 13em']
  const textAlign = ['left', 'center']

  return (
    <Container>
      <Flex flexDirection="column" mt="4em" mb="4em">
        <Box p={padd} width="100%">
          <Flex width="100%" justifyContent="center">
            <MainTitle textAlign={textAlign} mb="1rem">
              {data.title}
            </MainTitle>
          </Flex>
          <Description color="greyMedium" textAlign={textAlign} mb="1rem">
            {data.subtitle}
          </Description>
        </Box>
      </Flex>
      <Flex
        flexWrap={['wrap', 'wrap', 'nowrap']}
        p={[0, '0 6em']}
        mb="4em"
        alignItems="center"
      >
        <Box width={['100%', '100%', '60%']} pr={[0, '3em']}>
          <Description color="greyMedium">{formatted}</Description>
        </Box>
        <Box width={['100%', '100%', '40%']}>
          <Image
            style={{ maxWidth: '384px' }}
            path={data.images[0]}
            alt={data.title}
          />
        </Box>
      </Flex>
      <Flex mt="10em" mb="6em">
        <ImageTextured src={dotsCareers} alt={data.title} />
        <Box flex="1">
          <ImageRounded path={data.images[1]} alt={data.title} />
        </Box>
      </Flex>
    </Container>
  )
}
