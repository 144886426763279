import * as React from 'react'

import Button from '../../shared/Button'
import Card from '../../shared/Card'
import { Container } from '../../shared/Grid'
import { Box, Flex } from '../../shared/Helpers'
import Link from '../../shared/Link'
import { Caption, Description, Text, Title } from '../../shared/Typography'
import { IJobs, IOpenings } from './types'

interface IProps {
  data: {
    allHomerunJob: IJobs
    careers: {
      openings: IOpenings
    }
  }
}

export default function renderOpeningsCareers({ data }: IProps) {
  const padd = [0, '0 6em', '0 16em']
  const textAlign = ['left', 'center']

  const {
    careers: {
      openings: { description, title, button },
    },
  } = data

  const homerunJobs = data.allHomerunJob.edges

  return (
    <Container id="openings">
      <Flex flexDirection="column" mt="4em" mb="2em">
        <Box p={padd} width="100%">
          <Flex width="100%" justifyContent="center">
            <Title textAlign={textAlign} mb="1rem">
              {title}
            </Title>
          </Flex>
          <Description color="greyMedium" textAlign={textAlign} mb="1rem">
            {description}
          </Description>
        </Box>
      </Flex>
      <Flex width="100%" justifyContent="center" mb={['4em', '8em']}>
        <Flex maxWidth="747px" flexDirection="column" width="100%">
          {homerunJobs.map(el => (
            <Card key={el.node.id} width="100%" p={[null, '1.5em']} mb="1.5em">
              <Flex
                flexWrap={['wrap', 'wrap', 'nowrap']}
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Caption mb="0.3rem">{el.node.title}</Caption>
                  <Text mb={['0.5rem', 0]} color="greyMedium">
                    {el.node.type}
                  </Text>
                </Box>
                <Box>
                  <Link to={el.node.jobUrl}>
                    <Button ghost>{button}</Button>
                  </Link>
                </Box>
              </Flex>
            </Card>
          ))}
        </Flex>
      </Flex>
    </Container>
  )
}
