import * as React from 'react'

import Hero from '../components/Careers/Hero'
import Openings from '../components/Careers/Openings'
import LatestPosts from '../components/LatestPosts'
import Newsletter from '../components/Newsletter'
import SEO from '../components/SEO'
import Layout from '../layouts'

const CareersPage: React.FC = () => {
  return (
    <Layout>
      <SEO />
      <Hero />
      <Openings />
      <LatestPosts message="careers" />
      <Newsletter />
    </Layout>
  )
}

export default CareersPage
