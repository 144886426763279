import { graphql, StaticQuery } from 'gatsby'
import * as React from 'react'

import mergeEdges from '../../../utils/mergeEdges'
import { IProp } from './types'
import View from './view'

export default function queryOpeningsCareers() {
  return (
    <StaticQuery
      query={graphql`
        query OpeningCareers {
          allHomerunJob {
            edges {
              node {
                id
                title
                type
                jobUrl
              }
            }
          }
          allFoundationYaml {
            edges {
              node {
                careers {
                  openings {
                    title
                    description
                    button
                  }
                }
              }
            }
          }
        }
      `}
      render={({ allHomerunJob, allFoundationYaml }: IProp) => {
        const careers = mergeEdges(allFoundationYaml)

        if (!careers) {
          return null
        }

        const data = {
          ...careers,
          allHomerunJob,
        }

        return <View data={data} />
      }}
    />
  )
}
